
#location-select-menu{
	z-index: 1000;
  	height:300px;
	width: 250px;
	display: block;
	bottom: 0;
    left: 0;
	position: absolute;
	padding: 10px;
	background: white;
	border-radius: .4em;
	margin-bottom: 60px;
	margin-left: 10px;
	-webkit-box-shadow: 0px 0px 20px -5px rgba(3,3,3,1);
	-moz-box-shadow: 0px 0px 20px -5px rgba(3,3,3,1);
	box-shadow: 0px 0px 20px -5px rgba(3,3,3,1);	
}
@media screen and (max-height:370px){
	#location-select-menu{
	height:250px;
	}
}
@media screen and (max-height:310px){
	#location-select-menu{
	height:220px;
	}
}



@media screen and (max-height:280px){
	#location-select-menu{
	height:180px;
	}
}
@media screen and (max-height:240px){
	#location-select-menu{
	height:140px;
	}
}

.scrollable{
	height: 90%;
	overflow-y: auto;
}
.location-select-title{
	color: black;
	border-bottom: 1px solid grey;
	padding-bottom: 5px;
}

#location-select-menu ul {
	padding-bottom: 10px;
}
#location-select-menu ul li {
	padding-top: 10px;
}
#location-select-menu i.close {
	position: absolute;
	right:0;
	top:0;
	margin-right:5px;
	margin-top:5px;
	color:grey;
	font-size: 1.4em;
}

.online{
	color:#4caf50;
}

.offline{
	color:#f44336;
}

#location-select-menu:after{
content: '';
position: absolute;
bottom: 0;
left: 9%;
width: 0;
height: 0;
border: 21px solid transparent;
border-top-color: white;
border-bottom: 0;
border-left: 0;
margin-left: -10.5px;
margin-bottom: -21px;}

#location-select-menu li{
	list-style: none;
}