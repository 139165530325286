.analog-output-control{
  /* margin-left: -5px; */
   z-index: 10;
   background: rgba(194, 194, 194, 0.4);
  position: fixed;
   height:100%;
   width: 100%;
   top: 50px !important;
   padding-bottom: 60px;
   overflow-y: scroll
 }

 .analog-output-control{
   overscroll-behavior: none !important;
 }

 .analog-output-control.blinds .slider-button {
  margin-top:10px;
 }
 /* .analog-output-control.blinds .on-off-buttons-slider-inside{
   margin-bottom: 30px;
 } */
 .scene-add-form .analog-output-control{
  width:100%;
  position: fixed;
  left: 0;
 }

    .scene-add-form .analog-output-control .analog-output-middle{
      margin-top: 10%;
         margin-left: 50% !important;
        }

 @media (max-width:844px){
  .scene-add-form .analog-output-control{
  margin-left: -35px;
  }
  .scene-add-form .analog-output-control .analog-output-middle {
    position: fixed !important;
    left: 0 !important;
    width: 100% !important;
    margin-left: 0 !important; 
  }
 }
.on-off-buttons-slider{
  width: 100%;
  bottom: 0;
  position: absolute;
  margin-top: auto;
}

.on-off-buttons-slider-inside {
  /* width: 200px; */
    margin-right: auto;
    margin-left: auto;
}
.on-off-buttons-slider .slider-button{
  text-align: center;
vertical-align: middle;
line-height: 50px;
  display: inline-block;
  margin-left:3px;
  margin-right: 3px;
  border-radius: 8px
}

 @media screen and (max-width:500px){
   .analog-output-middle{
     width: 100% !important;
     height: 100% !important;
     margin-top: 0 !important;
   }
   .range-slider{
    height: 70%  !important;
   }
 }

 
 .range-slider.blinds{
   height: 76%;
 }

 .close{
  position: absolute;
	right:0;
	top:0;
	margin-right:5px;
	margin-top:5px;
	color:grey;
	font-size: 1.4em;
 }

 .light-on{
   bottom: 0;
 }

 .vertical-aligned{
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  position: relative;
  top: 50%;
 }

 @media (min-width:992px){
   .analog-output-control{
    /* margin-left: -5px; */
    z-index: 10;
    background: rgba(194, 194, 194, 0.4);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0 !important;
    padding-top:50px 
   }
   /* .analog-output-middle {
     margin-left:26% !important;
   } */
 }


 /* @media (min-width:1500px){
  .analog-output-middle {
    margin-left:28% !important;
  }
 } */

 .analog-output-middle{
  position: relative;
  /* height: 65%; */
  height: 450px;
  width: 300px;
  margin: 0 auto;
  z-index: 100;
  background: #D3D5D4;
  
  /* #133C55 */
 }

.range-slider{
  position: relative;
  height: 80%;
}
.analog-buttons-div{
  position: absolute;
  right: 0px;
  width: 50px;
  margin-left: auto;
  margin-right: 20%;
  display: inline-block;
  margin-top: -36%;
}

.analog-buttons-div.text-value-only{
  color: white;
  position: absolute;
  top: 45%;
  margin-top: 0;
  font-size:2.2em;
}

.slider-button{
  position: relative;
  height: 50px;
  width: 50px;
  /* background: blue; */
  /* border:1px solid #989191; */
  margin-top: 20%;
  /* border-radius: 30%; */
  color: #989191;
  background-color: rgba(103, 103, 103, 0.48);
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

.off-active {
  background: #ffa545;
  color: white
}
.on-active {
  background: #ffa545;
  color: white
}



.slider-button i {
  margin-top: -5px;
}


.slider-button:first-child i, .slider-button:last-child i  {
  margin-left: 5px;
}

input[type=range] {
    -webkit-appearance: none; 
    position: relative;
    top: 50%;
    display: inline-block;
    margin-left: -35px;
    margin-left: 0;
    width:100%;
  }
  input[type=range].analog{
    width:80% !important;
    padding: 15px 0;
    background: transparent;
  }
  
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    /* animate: 0.2s; */
    background: #f8a634; /* Old browsers */
    background: -moz-linear-gradient(left, #f8a634 46%, #ff7400 94%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #f8a634 46%,#ff7400 94%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #f8a634 46%,#ff7400 94%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8a634', endColorstr='#ff7400',GradientType=1 ); /* IE6-9 */
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }

  .blinds-slider-wrapper > input[type=range]:first-child::-webkit-slider-runnable-track{
    height: 190px !important;
    margin-left: 40%;
    border-radius: 1.3px;
    /* background: #87cefa; */
    border-top:1px solid black;
    border-right:4px solid black;
    /* -webkit-box-shadow:3px 0px 0px #636363; */
    border-left:3px solid black;
    border-bottom:1px solid black;
    overflow-y: hidden;
    background: url("../img/wind2.jpg");
    background-size: cover;
    

  }
  .blinds-slider-wrapper > input[type=range]:last-child{
    transform: rotate(180deg);
    width: 60% !important;
    margin-left: -40%;
    margin-top: 20px
  }
  .analog-slider-wrapper > input[type=range]:last-child{
    transform: rotate(360deg);
  }

  input[type=range]::-webkit-slider-thumb {
    cursor: pointer;
    /* border: 1px solid #f67c10; */
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background:white;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -14px;
    /* padding: 20px; */
    z-index: 111111;

  }

  .blinds-slider-wrapper .blinds-input {
    background: transparent
  }

  .blinds-slider-wrapper > input[type=range]:first-child::-webkit-slider-thumb {
    cursor: pointer;
    border: 1px solid #111111;
    border-radius: 0 !important;
    height: 200px;
    width: 12px;
    background:rgb(22, 22, 22);
    z-index: 10 !important;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -10px;
    box-shadow: -5px 0 0 5px #6d6d6d,-6px 0 0 6px #303030,-11px 0 0 11px #6d6d6d,-12px 0 0 12px #303030,-17px 0 0 17px #6d6d6d,-18px 0 0 18px #303030,-23px 0 0 23px #6d6d6d,-24px 0 0 24px #303030,-29px 0 0 29px #6d6d6d,-30px 0 0 30px #303030,-35px 0 0 35px #6d6d6d,-36px 0 0 36px #303030,-41px 0 0 41px #6d6d6d,-42px 0 0 42px #303030,-47px 0 0 47px #6d6d6d,-48px 0 0 48px #303030,-53px 0 0 53px #6d6d6d,-54px 0 0 54px #303030,-59px 0 0 59px #6d6d6d,-60px 0 0 60px #303030,-65px 0 0 65px #6d6d6d,-66px 0 0 66px #303030,-71px 0 0 71px #6d6d6d,-72px 0 0 72px #303030,-77px 0 0 77px #6d6d6d,-78px 0 0 78px #303030,-83px 0 0 83px #6d6d6d,-84px 0 0 84px #303030,-89px 0 0 89px #6d6d6d,-90px 0 0 90px #303030,-95px 0 0 95px #6d6d6d,-96px 0 0 96px #303030,-101px 0 0 101px #6d6d6d,-102px 0 0 102px #303030,-107px 0 0 107px #6d6d6d,-108px 0 0 108px #303030,-113px 0 0 113px #6d6d6d,-114px 0 0 114px #303030,-119px 0 0 119px #6d6d6d,-120px 0 0 120px #303030,-125px 0 0 125px #6d6d6d,-126px 0 0 126px #303030,-131px 0 0 131px #6d6d6d,-132px 0 0 132px #303030,-137px 0 0 137px #6d6d6d,-138px 0 0 138px #303030,-143px 0 0 143px #6d6d6d,-144px 0 0 144px #303030,-149px 0 0 149px #6d6d6d,-150px 0 0 150px #303030,-155px 0 0 155px #6d6d6d,-156px 0 0 156px #303030,-161px 0 0 161px #6d6d6d,-162px 0 0 162px #303030,-167px 0 0 167px #6d6d6d,-168px 0 0 168px #303030,-173px 0 0 173px #6d6d6d,-174px 0 0 174px #303030,-179px 0 0 179px #6d6d6d,-180px 0 0 180px #303030,-185px 0 0 185px #6d6d6d,-186px 0 0 186px #303030,-191px 0 0 191px #6d6d6d,-192px 0 0 192px #303030,-197px 0 0 197px #6d6d6d,-198px 0 0 198px #303030;
    /* padding: 20px; */
    z-index: 111111;
  }

  .blinds-slider-wrapper > input[type=range]:first-child::-webkit-slider-thumb::before{
    width: 300px;
    height: 100px;
    background: red;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: -webkit-linear-gradient(left, #f8a634 46%,#ff7400 94%); /* Chrome10-25,Safari5.1-6 */
  }
  .blinds-slider-wrapper > input[type=range]:first-child:focus::-webkit-slider-runnable-track {
    background: #87cefa;
    background: url("../img/wind2.jpg");
    background-size: cover;
    /* background-position: center center;             */

  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    /* animate: 0.2s; */
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: -moz-linear-gradient(left, #f8a634 46%, #ff7400 94%); /* FF3.6-15 */
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    /* animate: 0.2s; */
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type=range]::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #3071a9;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #367ebd;
  }

   .blinds-slider-wrapper > input[type=range]:first-child {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -ms-transform:rotate(180deg);
      }


  @media screen and (max-width: 880px) and (max-height:470px) {
  .analog-output-middle{
    /* transform: rotate(90deg); */
    width: 100%;
    height:100%;
    margin:0;
  }

  .vertical-aligned:not(.blinds-slider-wrapper){
    transform: rotate(0deg);
    -webkit-transform:  rotate(0deg);
      -ms-transform: rotate(0deg);
  }
  .range-slider {
    position: relative;
    height: 50%;
}
.analog-buttons-div.text-value-only {
  font-size: 2.2em;
  top:70%;
}

  input[type=range]:not(.blinds-input) {
    width: 80% !important; 
    }

    .analog-buttons-div{
      /* transform: rotate(90deg); */
      margin-top: -30%; 
      margin-right: 46%;
    }
  }



  .onoffswitch {
    position: relative; width: 90px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    border: 2px solid #999999; border-radius: 20px;
}
.onoffswitch-inner {
    display: block; width: 200%; margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block; float: left; width: 50%; height: 30px; padding: 0; line-height: 30px;
    font-size: 14px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "ON";
    padding-left: 10px;
    background-color: #34A7C1; color: #FFFFFF;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 10px;
    background-color: #EEEEEE; color: #999999;
    text-align: right;
}
.onoffswitch-switch {
    display: block; width: 18px; margin: 6px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 56px;
    border: 2px solid #999999; border-radius: 20px;
    transition: all 0.3s ease-in 0s; 
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px; 
}

@media screen and (max-width:500px){
.analog-output-on-scene-edit .analog-output-control{
right: 0;
position: fixed !important;
top: 0 !important;
margin-top: 40px;}}

/* 
.blinds-slider-wrapper input[type='range'] {
  overflow: hidden;
  width: 80px;
  -webkit-appearance: none;
  background-color: #9a905d;
}

.blinds-slider-wrapper  input[type='range']::-webkit-slider-runnable-track {
  height: 10px;
  -webkit-appearance: none;
  color: #13bba4;
  margin-top: -1px;
}

.blinds-slider-wrapper  input[type='range']::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 10px;
  cursor: ew-resize;
  background: #434343;
  box-shadow: -80px 0 0 80px #43e5f7;
} */

/** FF*/
.blinds-slider-wrapper > input[type="range"]:first-child::-moz-range-progress {
  background-color: #6d6d6d; 
}
.blinds-slider-wrapper > input[type="range"]:first-child::-moz-range-track {  

  background: rgb(239, 248, 199);
}
/* IE*/
.blinds-slider-wrapper input[type="range"]:first-child::-ms-fill-lower {
  background-color: #6d6d6d;
}
.blinds-slider-wrapper input[type="range"]:first-child::-ms-fill-upper {  
 
  background: rgb(239, 248, 199);
}

.fa.fa-times.pull-right.close{
  z-index: 20;
}
.slider-close{
  font-size: 2em;
  padding: 10px;
}

.vertical-aligned.blinds-slider-wrapper{
  top:0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.analog-output-middle.blinds{
  z-index:11;
  /* height: 65%; */
  height: 450px;
  width:400px;
  margin: auto
}
.analog-output-middle.blinds.hasGapsButtons{
  height: 480px;
}
/* @media (min-width: 992px){
.analog-output-middle.blinds {
    margin-left: 23% !important;
}} */
.animation-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-top: 30px;
}

/* ----------------------------animation----------------------------------- */

.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1); 
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  } 
}

.sk-cube-grid{
  z-index:9;
}

#thermostat-background, .analog-output-middle {
  border: none !important;
    /* background-image: radial-gradient(circle at 0% 0%, #3ec8f7, #4387c1 50%, #497080); */
    background-image: -webkit-radial-gradient(0% 0%, circle, #60aac3, #4387c1 50%, #497080);
    background-image: -o-radial-gradient(0% 0%, circle, #60aac3, #4387c1 50%, #497080);
    background-image: radial-gradient(circle at 0% 0%, #60aac3, #4387c1 50%, #497080);
    background-repeat: no-repeat;
    background-size: cover;
    /* background: url(http://ak7.picdn.net/shutterstock/videos/13807007/thumb/10.jpg); */
    /* background-size: 100vmax; */
    /* height: 100%; */
}

.thermometer-slider   input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  /* animate: 0.2s; */
  background: #f8a634; /* Old browsers */
 
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

.thermometer-slider  input[type=range]::-webkit-slider-thumb {
  cursor: pointer;
  border: none;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background:white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -13px;
  /* padding: 20px; */
  z-index: 111111;

}

.thermostat-middle, .analog-output-middle {
  -webkit-box-shadow: 4px 2px 35px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 4px 2px 35px 0px rgba(0,0,0,0.75);
box-shadow: 4px 2px 35px 0px rgba(0,0,0,0.75);
}

.analog-output-middle .close.slider-close{
  color: rgb(236, 236, 236)
}

.calibrate-option:hover{
  background-color: #2a6495 !important;
  color:white !important
}
/* 
input[type="range"] {
  transform: rotateZ(270deg);
}

.versstical-aligned{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
} */


.slider-button.pointer.animated.pulse, .btn.btn-default.pointer.animated.pulse, .infinite-animation{
  animation: pulse 1s infinite
}
.slider-button.pointer.animated.pulse:hover, .infinite-animation:hover{
  animation:none
}