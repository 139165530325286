@import url("location-select.css");
@import url("day-picker.css");
@import url("slider.css");
@import url("animate.css");
@import url("../icomon/style.css");
/* @import url("bootstrap/css/bootstrap.min.css"); */

* {
  padding: 0;
  margin: 0; }

.text-bold{
  font-weight: bold;
}
.bg-white{
  background-color: white;
}

.m-0{
  margin: 0 !important;
}
.mx-0{
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.my-0{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.link-underline-hover {
  position: relative;
  color: inherit;
  text-decoration: none;
  line-height: 24px;
}
.link-underline-hover:before, .link-underline-hover:after {
  content: '';
  position: absolute;
  transition: transform 0.3s ease;
}
.effect-1 {
  padding-top: 14px;
}
.effect-1:before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #0883a4;
  transform: scaleX(0);
}
.effect-1:hover:before {
  transform: scaleX(1);
}

.mt-1{
  margin-top: 1rem;
}

.mb-1{
  margin-bottom: 2.5rem;
}

.mt-15{
  margin-top: 1.5rem;
}

.mt-2{
  margin-top: 2rem;
}

.pt-1{
  padding-top: 1rem;
}

.pt-2{
  padding-top: 2rem;
}

@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  src: url('../noto-sans/noto-sans-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans'), local('NotoSans'),
       url('../noto-sans/noto-sans-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../noto-sans/noto-sans-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../noto-sans/noto-sans-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../noto-sans/noto-sans-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../noto-sans/noto-sans-v7-latin-regular.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* body, html {
  height: 100%; } */

body, html, h1, h2, h3, h4, h5, h6 {
  font-family:"sans-serif", "NotoSans",sans-serif; 
}

div:focus,
a:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
  outline-style: none !important; }

/* .btn, a, div, p, input, input:focus {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  -webkit-tap-highlight-color: transparent !important; } */

  @keyframes gradientLine {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none; }

  html{
    height: 100vh;
  }
  body {
    position: fixed !important;
    min-height: 100vh;
    min-width:100%;
  }

html {
  box-sizing: border-box;
  touch-action: manipulation; }

body {
  position: relative;
  margin: 0;
  /*font-family: Arial, Helvetica, sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: radial-gradient(circle at 0% 0%, #60aac3, #4387c1 50%, #497080);
  background-repeat: no-repeat;
  background-size:cover;

}
#root{
  height: 100vh !important;
}

/* @media screen and (min-width:500px){
  input[type="text"], input[type="number"], .DayPickerInput {
    border-bottom: 1px solid #D2D2D2 !important;
  }
} */


select:disabled {
  opacity: 0.6; }

.pointer{
  cursor: pointer;
}
#header {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 1;
  top: 0;
  height: 50px;
  text-align: center;
  background: #1e5799;
  /* Old browsers */
  background: -moz-linear-gradient(left, #1e5799 0%, #3498db 30%, #3498db 47%, #3498db 60%, #3498db 77%, #2989d8 94%, #2989d8 100%, #7db9e8 100%, #3498db 101%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #1e5799 0%, #3498db 30%, #3498db 47%, #3498db 60%, #3498db 77%, #2989d8 94%, #2989d8 100%, #7db9e8 100%, #3498db 101%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #1e5799 0%, #3498db 30%, #3498db 47%, #3498db 60%, #3498db 77%, #2989d8 94%, #2989d8 100%, #7db9e8 100%, #3498db 101%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#3498db',GradientType=1 );
  /* IE6-9 */ }

.header-icon {
  position: absolute;
  right:0;
  top:0;
  padding-right: 20px;
  padding-bottom: 18px;
  padding-top: 16px;
  float: left;
  color: white;
  padding-left: 20px;
  font-size: 1.2em;
 }

 .top70{
   top:70px  !important;
 }
 .notification-if-fullscreen{
   height: 70px !important;
   padding-top:20px  !important;
    /* justify-content: center; */
 }

 .header-back-icn {
  position: absolute;
  left:0;
  top:0;
  padding-right: 20px;
  padding-bottom: 18px;
  padding-top: 16px;
  float: left;
  color: white;
  padding-left: 20px;
  font-size: 1.2em;
}

#header.header-status-bar-filler{
  height: 70px;
  padding-top: 20px;
}

#header.header-status-bar-filler>.header-icon{
  padding-top: 36px !important;
}

#header.header-status-bar-filler>.header-back-icn{
  padding-top: 36px !important;
}


 .ace_gutter{
   z-index: 1;
 }

.header-icon:first-child {
  float: right; }

.side-bar-icon {
  position: absolute;
    margin-left: auto;
    right: 0;
    font-size: 1.7em;
    color: #777;
    padding: 15px; }

#header span {
  display: inline-block;
  /* margin-top: 15px; */
  color: white;
  font-weight: bold; }

#footer{
   padding: 15px;
  position: fixed;
  z-index: 11 !important;

  right: 0;
  bottom: 0;
  height: 50px;
  text-align: center;
  color: white;
  z-index: 1; 
  background: #1e5799;
  /* Old browsers */
  background: -moz-linear-gradient(left, #1e5799 0%, #3498db 30%, #3498db 47%, #3498db 60%, #3498db 77%, #2989d8 94%, #2989d8 100%, #7db9e8 100%, #3498db 101%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #1e5799 0%, #3498db 30%, #3498db 47%, #3498db 60%, #3498db 77%, #2989d8 94%, #2989d8 100%, #7db9e8 100%, #3498db 101%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #1e5799 0%, #3498db 30%, #3498db 47%, #3498db 60%, #3498db 77%, #2989d8 94%, #2989d8 100%, #7db9e8 100%, #3498db 101%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#3498db',GradientType=1 );
  /* IE6-9 */ }

.full-width {
  width: 100% !important; }

.side-menu {
  padding: 0;
  overflow: hidden;
  display: inline-block;
  background: white;
  height: 100%; }

#side-user-info {
      /* background: #F1F1F1; */
      height: 50px;
      /* width: 80%; */
      text-align: center;
      overflow: hidden;
      display: flex;
      justify-content: left;
      align-items: center;
    ;}

#side-user-info img {
  float: left;
  margin-left: 10px;
  position: relative;
  width: 40px;
  padding-top: 5px; }

#side-user-info div {
  padding-left: 8px }

/* #side-user-info div:last-child {
  margin-top: 14px;
  margin-left: 10px;
  padding-left: 5px
  float: left; } */

#side-user-info div:first-child {
  float: left; }

#menu-list {
  /* margin-right: -30px; */
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: 96%;
margin-left: 0 }

#menu-list > span {
  /* display: inline-block; */
  /* margin-top: 10px; */
  font-weight: bold;
  font-size: 1.2em;
  /* text-align: left !important; */
  /* text-align: left; */
  /* display: inline-block; */
  /* width: 90%; */
  /* border-bottom: 1px solid; */
    /* border-width: thin;  */

  /* padding: 5px 3px; */
  box-shadow: 0 3px 2px -2px gray;


  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding-right: 10px;
  padding-left: 10px;

}

#menu-list > span > i{
  padding: 4px 10px
}

.choices {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  color: black;
  margin: 10px 0;
  padding: 5px 0;
  font-size: 1.2em; }
.transparent-border{
  border:5px solid transparent;
}

.scene-side a {
  display: inline-block !important;
  /* width: 70% !important; */
}
.scene-side{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding-left: 5px;
  padding-right: 5px;
}
.center-buttons{
  width:280px;
  margin-right:auto;
  margin-left:auto;
  padding-bottom: 30px
}

/* .center-buttons a, .center-buttons div{
  margin-top:8px;
  color:#7CBDBA;
  display: inline-block;
  line-height: 1em;
  padding: 0 1em;
  background: #34343E;
  border-radius: 0.125em;
  background-clip: padding-box;
  border: 1px solid #EEE !important;
  background: none;
  border-color: #7CBDBA !important;
  padding: 12px;

}

.center-buttons div:first-of-type{
  border-color : red !important;
  color: red !important;
}
.center-buttons div:last-child{
  border-color: #5bc0de !important;
  color: #5bc0de;
}

.center-buttons div:first-of-type:hover, .center-buttons div:last-child:hover{
  border-color : white !important;
  color: white !important;
} */
.center-buttons div {
  cursor: pointer;
}
.center-buttons button, .center-buttons a, .center-buttons div{
  margin-top:12px;
}

.smaller-btn{
  width:48.5%;
}
.center-buttons button:disabled, .center-butttons button[disabled]{
  opacity: .65;
  cursor: not-allowed;
}
.scene-side i:last-child {
  /* display: inline; */
  font-size: 1.5em;
  padding:8px

  /* display: inline; */
  /* padding-right: 30px; */
  /* padding-top: 22px; */
  /* padding-left:12px; */
  /* display: inline-block; */
  /* padding-bottom: 10px; */
  /* margin-bottom: -10px; */
  /* margin-left: 0 */

}

/* .scene-side i{
  padding:10px
} */

/* .scene-side i:last-child.fa-spinner { */
  /* font-size: 1.5em;
  display: inline-block;
  margin-right: 30px;
  margin-top: 22px;  */
  /* padding-left:6px  */
/* } */

/* .selectable {
  -moz-user-select: text;
  -khtml-user-select: auto;
  -webkit-user-select: auto ;
  -ms-user-select: auto;
  user-select: auto;
} */



.margin-t10 {
  margin-top:10px;
}

.calendar-days {
  width:50%;
  display: block;
  height:60px;
  overflow-y: scroll;
}
.calendar-time {
  display: block;
  width:100%;
  position: absolute;
  bottom:0;
  margin-left: auto;
  margin-right:auto;
}
.device .toggle {
  z-index: 1;
}

.pointer{
  cursor: pointer;
}

.fl-left {
  float: left; }

.choices:hover {
  cursor: pointer;
  color: black;
  text-decoration: none; }

.choices:visited {
  text-decoration: none;
  color: black; }

#menu-list a {
  margin-left: 10px; }

#app-content-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  /* margin-bottom: 50px !important; */
  /*background-color: #F1F1F1;*/
}

.content {
  overflow-y: auto;
  position: absolute;
  /* height: 90%; */
  /* padding: 0 0 !important; */
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  /* padding-bottom: 50px !important; */
  display: inline-block;
  background-color: #F1F1F1;
  -ms-overflow-style: none;
  overflow-x: hidden;
  background-clip: padding-box;
  border: 5px solid transparent;
  /* margin-top: 50px; */
  /* margin-bottom: 50px;  */
  right: 0;
  border: 0px;
  top: 50px;
  /* bottom: 0; */
  bottom: 50px
}

.dashboard-widgets-wrapper.content {
  padding-bottom: 0 !important;
}

.content-w-background {
  position: absolute;
    height: 100%;
    width: 100%;
  overflow: auto;
  border: none !important;
  background: #096695; 
  background-image: radial-gradient(circle at 0% 0%, #60aac3, #4387c1 50%, #497080);
  background-repeat: no-repeat;
  background-size: cover;
}

.content-w-background h5 {
  color: white;
  margin-left: 10px;
  margin-bottom: 10px;
font-size:1em !important; }

.device-inside {
  border-radius: 15px;
  /* border: 3px solid rgb(255, 255, 255); */
  background-color: rgba(37, 118, 136, 0.3);
  margin:0 !important;
  position: relative;
  height: 100%;
  color:white;
   border: 0.1px solid rgba(0, 0, 0, 0); 
  -webkit-box-shadow: 0px 0px 15px -5px rgba(255,255,255,1);
  -moz-box-shadow: 0px 0px 15px -5px rgba(255,255,255,1);
  box-shadow: 0px 0px 15px -5px rgba(255,255,255,1);
}
.device-inside.back-grey{
  background: #d8d8d8;
}
.unassigned-device-inside{
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.3);
  margin:0 !important;
  position: relative;
  height: 100%;
  border: 1.5px solid green;
}

.color-white {
  color: white;
}

.assign-link{
  margin-top:20px;
  background: #5ea6c3;
  display: block;
  margin: 20px auto;
  width:80%;
  min-width:30px;
  border-radius: 5px;
}
.assign-link:hover{
  text-decoration: none !important;
  color:white;
  background-color:#3598dc;
}

.device-inside i:not(.interruptState){
  color:white;
}
.device {
  height: 170px;
  text-align: center;
  background: transparent;
  padding: 0;
  background-clip: padding-box;
  border: 5px solid transparent;
  border-radius: 12px; }

.groups-dashboard {
  /* font-family: "Roboto", sans-serif; */
  height: 100px;
  text-align: center;
  background: white;
  padding: 6px;
  background-clip: padding-box;
  border: 5px solid transparent;
  border-radius: 12px;
cursor: pointer; }

.event-list-item{
  padding:5px;
}

.groups-dashboard .fa-calendar {
  font-size:3em
}

.form-control {
  padding:0 !important;
  margin-top:5px !important;
  border-bottom: 1px solid #ccc !important;
  background-image:none !important
}

.groups-dashboard i:not(.interruptState) {
  float: left;
  color: #0f9ef7; }

.groups-dashboard div:first-child {
  height: 50px;
  border-bottom: 1px solid black; }

.groups-dashboard div:last-child {
  color: grey;
  margin-top: 3px; }

.center-div {
  position: relative;
  width: auto;
  margin: 0 auto; }

.device.groups div span {
  margin-left: 0 !important; }

.toggle-button label span {
  width: 28%;
  margin: 0 10px; }

.toggle-button label span a {
  background-color: #3498db; }

.big-icon {
  font-size: 3em;
  color: #717171; }

.big-icon.active {
  /* text-shadow: 1px -2px 22px #3498db;  */
  text-shadow: 1px -2px 22px white;
  color: white !important;
}
.dimmer {
  float: left !important;
  left: 0;
}

.small-icon {
  position: absolute;
  font-size: 1.1em;
  top:0;
  right:0;
  padding: 10px 10px 8px 8px;
}

.device-inside .clock-icon{
  position: absolute;
  font-size: 1.1em;
  bottom:0;
  right:0;
  padding: 10px 10px 8px 8px;
  z-index: 1;
}

.device div, .device button {
  margin-top: 9px; }

#menu-list a > span {
  margin-left: 10px; }

.sensorState {
  margin-top: 10px; }

.sensorState span {
  font-size: 1.8em;
  color: #3498db; }

.toggle-button > label {
  margin-left: 15px; }

.device div:first-child span {
  margin-left: 15px;
  /* font-weight: bold;  */
}

  @media screen and (max-width: 500px) {
    .device div:first-child span {
      font-weight: normal !important; }
    }


    @media screen and (min-width: 580px) and (max-width: 680px) {
      .content-w-background {
        font-size: 16px;
      }
      .device-inside .fa-calendar.clock-icon {
        font-size: 1.3em;
      }
      .device-inside .fa-cogs {
        font-size: 1.3em;
      }
      .short-text.no-margin{
        font-size: 17px;
      }
    }
     



.active {
  color: #3498db !important; 
/* color:#48db89 !important; */
}

/* ensure complete visibilty of last device on small screens */
.row.text-center {
  margin-bottom: 35px; }

@media screen and (max-width: 991px) {
  .content {
    right: 0;
    left: 0; } }

    /* @media screen and (max-width: 410px) {
     .scene-side a{
       width: 60% !important;
     } } */
     /* @media screen and (max-width: 311px) {
      .scene-side a{
        width: 50% !important;
      } } */

      /* @media screen and (max-width: 250px) {
        .scene-side a{
          width: 30% !important;
        } } */

@media screen and (max-height: 370px) {
  .bottom-with-margin {
    margin-bottom: 35px !important;
  height: 15px !important;
  } }

    @media screen and (max-width: 900px) and (max-height:500px) {
      .server-notification-tile:first-child {
        margin-top:100px !important;
       }
      }

.toggle {
  -webkit-appearance: none;
  appearance: none;
  width: 62px;
  height: 28px;
  display: inline-block;
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  outline: none;
  border: none;
  cursor: pointer;
  /* background-color: #707070; */
  background-color: rgba(103, 103, 103, 0.48);
  transition: background-color ease 0.3s; }

.toggle:before {
  content: "on off";
  display: block;
  position: absolute;
  z-index: 2;
  width: 27px;
  height: 24px;
  background: #fff;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  font: 10px/28px Helvetica;
  text-transform: uppercase;
  font-weight: bold;
  text-indent: -22px;
  word-spacing: 37px;
  color: #fff;
  text-shadow: -1px -1px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s; }

.toggle:checked {
  background-color: #f8a632;
  /* background-color:#48db89 !important; */
  
}



.toggle-window-device {
  -webkit-appearance: none;
  appearance: none;
  width: 80px;
  height: 28px;
  display: inline-block;
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  outline: none;
  border: none;
  cursor: pointer;
  /* background-color: #707070; */
  background-color: rgba(103, 103, 103, 0.48);
  transition: background-color ease 0.3s; }

.toggle-window-device:before {
  content: "open closed";
  display: block;
  position: absolute;
  z-index: 2;
  width: 27px;
  height: 24px;
  background: #fff;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  font: 10px/28px Helvetica;
  text-transform: uppercase;
  font-weight: bold;
  text-indent: -31px;
  word-spacing: 31px;
  color: #fff;
  text-shadow: -1px -1px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s; }

.toggle-window-device:checked {
  background-color: #f8a632;
  /* background-color:#48db89 !important; */
  
}

.livestream.toggle:before {
  content: "stream images";
  word-spacing: 32px;
  text-indent: -45px;

}
.livestream.toggle:checked:before{
  left: 50px;

}

.livestream.toggle{
  width: 80px;
}


.toggle:checked:before {
  left: 32px; }

  .toggle-window-device:checked:before {
    left: 50px; }

/* *::-webkit-scrollbar {
  display: none; } */

@media screen and (max-width: 991px) {
  .side-menu {
    position: relative;
    height: 100%;
    float: right;
    z-index: 1111111111111;
    width: 60%;
    font-size: 0.9em;
    border-left: 2px solid #D6D6D6;
    margin-bottom: -50px;
    margin-right: 0; }
  .side-menu #menu-list {
    margin-left: 0;
    margin-bottom: 10px; }
  #header {
    position: fixed;
    top: 0;
    z-index: 0; }
  #footer {
    position: fixed;
    bottom: 0;
    z-index: 11; } }

.side-menu-visibility {
  visibility: hidden; }

@media screen and (min-width: 992px) {
  .side-menu, .side-menu-visibility {
    visibility: visible; }
  /* .side-bar-icon {
    display: none; } */
  .text-center {
    position: relative;
    display: block; } }

.form-group {
  margin-top: 0; }

@media screen and (min-width: 768px) {
  .form-horizontal {
    margin-top: 20px;
    margin-right: 100px;
    margin-left: 100px;
    padding-left: 0; } }

select {
  background: rgba(0, 0, 0, 0.01);
  padding-left: 0px !important;
  height: 33px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-color: #D2D2D2; }

.select-group {
  margin-top: 16px; }

@media screen and (min-width: 1200px) and (max-width: 15151px) {
  .short-text {
    width: 75% !important;
    white-space: nowrap; }
  .short-text-group {
    height: 46px;
    display: inline-block;
    width: 100px !important;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-overflow: ellipsis; } }

@media screen and (max-width: 386px) {
    .short-text {
      width: 75% !important; 
    }
}

@media screen and (max-width: 286px) {
  .dialog-center {
    height: 150px !important; 
  }
}

@media screen and (max-width: 392px) {
  .short-text-group {
    height: 46px;
    display: inline-block;
    width: 70px !important;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-overflow: ellipsis; } }

@media screen and (max-width: 292px) {
  .short-text-group {
    width: 40px !important; } }

.select-icon {
  border-radius: 5px;
  background: #cccccca8;
  display: inline-block;
  width: 50px;
height: 50px;
text-align: center }

.select-icon .big-icon {
  padding-right: 3px;
  padding-left: 2px;
  padding-top: 3px; }

.hidden-icon-select {
  visibility: hidden;
  overflow: hidden;
  height: 0;
  width: 0;
  margin-top: 0; }



  div.boxItem { 
    height:50px;
    width:50px;
    margin-top:5px;
    display: inline-block;
    border: 1px solid black;
    padding: 0.3rem;
 }

 div.boxItem input {
   display: block;
   margin: 0 auto;
 } 

 .bckg-orange {
   background: #f8a632 !important;
 }
 .bckg-red{
   background: #e03a3a !important;
 }


 .checkboxDays {
  width: 25px;
  margin: -3px 12px;
  position: relative;
}
.checkboxDays label {
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: #eee;
  border: 1px solid #ddd;
}.checkboxDays label:after {
  opacity: 0.2;
  content: '';
  position: absolute;
  width: 9px;
  height: 5px;
  background: transparent;
  top: 5px;
  left: 4px;
  border: 3px solid #333;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.checkboxDays label:hover::after {
	opacity: 0.5;
}
.checkboxDays input[type=checkbox]:checked + label:after {
	opacity: 1;
}
/* * :not([type="range"]), * :not(::-webkit-slider-runnable-track), * :not(::-webkit-slider-thumb) {    
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
} */
*:not(input):not(.selectable-text) {    
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.file-content div p, .selectable-text{    
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}
input[type="text"], input[type="number"], input[type="range"], textarea {
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}
  .rotate {transform: rotate(314deg) scale(1.68) skew(-1deg) translate(-6px);
    -webkit-transform: rotate(314deg) scale(1.68) skew(-1deg) translate(-6px);
    -moz-transform: rotate(314deg) scale(1.68) skew(-1deg) translate(-6px);
    -o-transform: rotate(314deg) scale(1.68) skew(-1deg) translate(-6px);
    -ms-transform: rotate(314deg) scale(1.68) skew(-1deg) translate(-6px);
    padding-bottom: 0 !important; 
  }
  .link-icn{
    cursor: pointer;
    margin:0 5px;
    padding-top: 1em;
    padding-bottom: 1.5em;
  }
  
  div.line {
    display: inline-block;
    border-top: 1px solid black;
    width: 2em;
  }

.icon-selector {
  margin-left: 0;
  background: #e6e6e6;
  display: block;
  margin-top: 5px;
  max-height: 225px;
    overflow-y: scroll;
  /* margin-left: 10px; */ }

.icon-selector i {
  margin: 0 5px;
  padding-top: 3px;
  padding-bottom: 3px; }

.icon-selector i:hover {
  background: #3498db; }

.short-text {
  margin-top:5px;
  margin-left:0;
  display: inline-block;
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.short-text-group {
  display: inline-block;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis; }
.slave-status-info 
  {
    background-color: rgba(130, 130, 130, 0.6);
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    top:0
  }


.slave-status-info div div {
  background-color: #EAEAEA;
  border: 1px solid #EAEAEA;
  border-radius: 5px;
  width: 60%;
  margin:10px auto;
}
@media screen and (max-width:890px){
  .slave-status-info div div {
  width:90%;
  }
}

.slave-status-info .table {border-radius: 5px;
  width: 50%;
  margin: 0px auto;
  float: none;
}

.delete-dialog {
  background-color: rgba(130, 130, 130, 0.6);
  position: fixed;
  top:0;
  left:0;
  z-index: 112;
  width: 100%;
  height: 100vh; }

  hr {
   margin:0 !important;
   position: relative;
   bottom: 0;
 }
   
 hr.style-rainbow {
     border: 0;
     height: 3px;
     background: #1e5799;
     /* Old browsers */
     background: -moz-linear-gradient(left, #1e5799 0%, #3498db 30%, #3498db 47%, #3498db 60%, #3498db 77%, #2989d8 94%, #2989d8 100%, #7db9e8 100%, #3498db 101%);
     /* FF3.6-15 */
     background: -webkit-linear-gradient(left, #1e5799 0%, #3498db 30%, #3498db 47%, #3498db 60%, #3498db 77%, #2989d8 94%, #2989d8 100%, #7db9e8 100%, #3498db 101%);
     /* Chrome10-25,Safari5.1-6 */
     background: linear-gradient(to right, #1e5799 0%, #3498db 30%, #3498db 47%, #3498db 60%, #3498db 77%, #2989d8 94%, #2989d8 100%, #7db9e8 100%, #3498db 101%);
     /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#3498db',GradientType=1 );
     /* IE6-9 */ 
 }
.dialog-center {
  width: 350px;
  border-radius: 5px;
  /* border:1px solid rgb(148, 148, 148);   */
  height: 130px;
  background-color: rgb(225, 225, 225);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 200px;
  -webkit-box-shadow: 0px 0px 20px -5px rgba(3,3,3,1);
  -moz-box-shadow: 0px 0px 20px -5px rgba(3,3,3,1);
  box-shadow: 0px 0px 20px -5px rgba(3,3,3,1)}

  @media screen and (max-width:350px){
    .dialog-center {
      width: 280px;
    }
  } 
  @media screen and (max-width:290px){
    .dialog-center {
      width: 250px;
    }   
  }
  @media screen and (max-width:260px){
    .dialog-center {
      width: 220px;
    }
  }

.dialog-content {
  width: 90%;
  height: 90%;
  overflow-y: scroll;
  margin: 0 auto;
  padding: 10px;
  position: relative;
margin-top: 8px; }

.dialog-message-content {
  width: 90%;
  height: 70%;
  overflow-y: scroll;
  margin: 0 auto;
  padding: 10px;
  position: relative;
margin-top: 8px; 
}

.dialog-button-wrapper {
  height: 20%;
  background: #dedede;
}

.dialog-content button:last-child{
  margin-left: 10px;
}

  .dialog-title{
    color: white;
    display:table;
    width: 100%;
    height:25%;
    background: #1e5799;
    /* Old browsers */
    background: -moz-linear-gradient(left, #1e5799 0%, #3498db 30%, #3498db 47%, #3498db 60%, #3498db 77%, #2989d8 94%, #2989d8 100%, #7db9e8 100%, #3498db 101%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #1e5799 0%, #3498db 30%, #3498db 47%, #3498db 60%, #3498db 77%, #2989d8 94%, #2989d8 100%, #7db9e8 100%, #3498db 101%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #1e5799 0%, #3498db 30%, #3498db 47%, #3498db 60%, #3498db 77%, #2989d8 94%, #2989d8 100%, #7db9e8 100%, #3498db 101%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#3498db',GradientType=1 );
    /* IE6-9 */ 
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid rgb(194, 194, 194);
  }

  .dialog-title span{
    display:table-cell;
    vertical-align:middle;
    padding-left: 10px;
    padding-right: 10px;
  }
  .dialog-title span i{
    font-size: 1.3em;
  }

.dialog-content div {
  width: auto;
  position: relative;
  margin-left: 0;
  margin-right: 0; }

  .no-location-dialog {
    width:70%;
    margin: 20px auto;
    /* background: rgba(144, 144, 144, 0.33); */
    color: white;
    height: 50%;
  }
  @media screen and (max-width:680px)
  {
    .no-location-dialog{
      width:90%
    }
  }

  .no-location-dialog-body{
    margin-top: 80px; 
  }

  .no-location-container{
    height: 100%;
  }
.reconnecting-center{
  margin-top:120px;
}
.short-text-wide {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.bottom-with-margin {
  margin-bottom: 15px;
  height: 25px; }

  .bottom-with-dblmargin {
    margin-bottom: 25px;
    height: 35px; }

.no-margin {
  margin: 0 !important; }

.padding-l-ten {
  padding-left: 10px;
  margin-top: 25px; }

.marg-top-fifteen {
  margin-top: 15px !important; }


#menu-list a {
  margin-top: 15px;
  display: block;
  /* width: 80%; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.device div a {
  font-size: 0.9em; }

a, a:link, a:hover, a:visited, a:active {
  text-decoration: none !important;
  border: none !important;
  box-shadow: none !important; }

.checkboxes {
  height: 150px; 
}
.checkboxes span, .checkboxes label, .checkboxes i {
  margin-top:5px;
}
.checkboxes i.fa.fa-check-square-o, .checkboxes i.fa.fa-square-o{
  margin-top: 0
}
.scene-checks{
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}
.scene-checks i{
  font-size:2em;
}

.group-not-selected {
  background-color: #ffffff; }

.group-selected {
  background-color: #CEE5F2; }

.sel-group {
  margin: 5px 0; }

.sel-group span {
  font-weight: bold; }

.notification-success {
  top: 0;
  position: absolute;
  height: 50px;
  float: right;
  z-index: 1111;
  right: 0;
  color: #4F8A10;
  background-color: #DFF2BF; }

.notification-success span, .notification-unsuccess span {
  display: block;
  margin-top: 15px; }

.notification-unsuccess {
  top: 0;
  position: absolute;
  height: 50px;
  float: right;
  z-index: 1111;
  right: 0;
  color: #D8000C;
  background-color: #FFD2D2; }

.checkboxes {
  height: 70px; }

.checkboxes.scene {
  height: 60px; 
}

.checkboxes div {
  display: inline-block;
  margin-top: 4px; }

.checkboxes div {
  display: inline-block; }
.days {
  background: white;
  margin-right: 15px;
  margin-top: 5px;
  height:50px;
  width:50px;
  display: inline-block;
  /* width:50px; */
}

.days input {
  display: block;
  margin-right: auto;
  margin-left:auto;
}
.checkboxes div:nth-child(2), .checkboxes div:nth-child(3) {
  margin-top: 15px; }

.checkboxes div:nth-child(2) span {
  font-size: 1.1em; }

.regular-checkbox {
  display: none; }

.regular-checkbox + label {
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative; }

.regular-checkbox + label:active, .regular-checkbox:checked + label:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1); }

.regular-checkbox:checked + label {
  background-color: #e9ecee;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7; }

.regular-checkbox:checked + label:after {
  content: '\2714';
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: #99a1a7; }

.device span:hover {
  cursor: default; }

.sensor-state-value {
  font-size: 1.8em;
  color: white; }

.sensor-state i {
  font-size: 4em;
  color: white; }

.scene-form {
  display: block; }

.disabled-device {
  background-color: rgba(241, 241, 241, 0.32) }

.disabled-device i.active {
  color: #6767677a !important; }

.disabled-device input:checked {
  background-color: #6767677a !important }

.add-device-to-scene-dialog{
  background-color:#d8d8d8;  
}
.add-device-to-scene-buttons{
  margin-left: auto !important;
  margin-right:auto !important;
  width:40%;
}

.devices-to-add{
  background: white;
  width: 48%;
  display: inline-block;
  margin:5px 0;
  height: 30px;
  line-height: 30px;
  text-align: center;
  vertical-align: center;
}

.devices-to-add span {
  height: 80%;
  width: 70%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.devices-to-add i{
  float: left;
  margin-left: 5px;
  margin-top: 5px;
  font-size: 1.1em;
}

.devices-to-add label{
  margin-top: 5px;
  margin-right: 5px;
  float: right
}

.devices-to-add:nth-child(2n) {
  float: right;
}
.add-devices-to-scene-div{
  margin-bottom: 5px;
  width:98%;
  margin: 0 auto;}
@media screen and (max-width:665px) { 
  
.devices-to-add{
  width:98%
}
.devices-to-add:nth-child(2) {
  float: none;
}
}

.server-notification-tile:first-child{
margin-top:200px;
}
/*-----------------------------------error------------------------------------------ */
.dialog-center-error {
  width: 350px;
  border-radius: 5px;
  overflow: hidden;
  /* border:1px solid rgb(148, 148, 148);   */
  height: 300px;
  background-color: rgb(225, 225, 225);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
   margin-top: 15%; 
  position: absolute;
  -webkit-box-shadow: 0px 0px 20px -5px rgba(3,3,3,1);
  -moz-box-shadow: 0px 0px 20px -5px rgba(3,3,3,1);
  box-shadow: 0px 0px 20px -5px rgba(3,3,3,1)}
@media screen and (max-width:500px) {
  .dialog-center-error {
    width: 90%;
    height: 300px;
    margin-top:auto !important;
  }

}
@media screen and (max-width:800px) and (max-height:500px) {
  .dialog-center-error {
    width: 90%;
    height: 300px;
    margin-top:auto !important;
  }

}

.dialog-header {
  display:table;
    width: 100%;
    height:10%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid rgb(194, 194, 194);

}
  .dialog-header-error{
    
    color: white;
    display:table;
    width: 100%;
    background: #fe0008; /* Old browsers */
    background: -moz-linear-gradient(left, #fe0008 17%, #bf011a 78%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #fe0008 17%,#bf011a 78%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #fe0008 17%,#bf011a 78%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fe0008', endColorstr='#bf011a',GradientType=1 ); /* IE6-9 */
    
  }

  .dialog-header-warning {
    color: white;
    display:table;
    width: 100%;
    background: #ffa84c; /* Old browsers */
    background: -moz-linear-gradient(left, #ffa84c 10%, #ffa84c 19%, #ffa84c 23%, #ffa84c 28%, #ff7b0d 45%, #ff7b0d 45%, #ff7b0d 47%, #ff7b0d 53%, #ff7b0d 62%, #ff7b0d 72%, #ff7b0d 80%, #ff7b0d 90%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ffa84c 10%,#ffa84c 19%,#ffa84c 23%,#ffa84c 28%,#ff7b0d 45%,#ff7b0d 45%,#ff7b0d 47%,#ff7b0d 53%,#ff7b0d 62%,#ff7b0d 72%,#ff7b0d 80%,#ff7b0d 90%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #ffa84c 10%,#ffa84c 19%,#ffa84c 23%,#ffa84c 28%,#ff7b0d 45%,#ff7b0d 45%,#ff7b0d 47%,#ff7b0d 53%,#ff7b0d 62%,#ff7b0d 72%,#ff7b0d 80%,#ff7b0d 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffa84c', endColorstr='#ff7b0d',GradientType=1 ); /* IE6-9 */
   
  }

  .dialog-header-success {
    color: white;
    background:#28a745
  }

  .dialog-header-info {
    background: #03a9f4;
    color: white
  }


.dialog-title-warning{
  color: white;
    display:table;
    width: 100%;
    height:30px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffa84c+10,ffa84c+19,ffa84c+23,ffa84c+28,ff7b0d+45,ff7b0d+45,ff7b0d+47,ff7b0d+53,ff7b0d+62,ff7b0d+72,ff7b0d+80,ff7b0d+90 */
    background: #ffa84c; /* Old browsers */
    background: -moz-linear-gradient(left, #ffa84c 10%, #ffa84c 19%, #ffa84c 23%, #ffa84c 28%, #ff7b0d 45%, #ff7b0d 45%, #ff7b0d 47%, #ff7b0d 53%, #ff7b0d 62%, #ff7b0d 72%, #ff7b0d 80%, #ff7b0d 90%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ffa84c 10%,#ffa84c 19%,#ffa84c 23%,#ffa84c 28%,#ff7b0d 45%,#ff7b0d 45%,#ff7b0d 47%,#ff7b0d 53%,#ff7b0d 62%,#ff7b0d 72%,#ff7b0d 80%,#ff7b0d 90%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #ffa84c 10%,#ffa84c 19%,#ffa84c 23%,#ffa84c 28%,#ff7b0d 45%,#ff7b0d 45%,#ff7b0d 47%,#ff7b0d 53%,#ff7b0d 62%,#ff7b0d 72%,#ff7b0d 80%,#ff7b0d 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffa84c', endColorstr='#ff7b0d',GradientType=1 ); /* IE6-9 */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid rgb(194, 194, 194);
}
  .dialog-header-error span, .dialog-title-warning span, .dialog-header-warning span, .dialog-header-info span, .dialog-header-success span{
    display: inline-block;
      margin-top: 5px; 
      margin-left: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background: transparent;
  }
  .dialog-header-error i, .dialog-title-warning i, .dialog-header-warning i, .dialog-header-info i, .dialog-header-success i{
      margin-top: 5px; 
      margin-right: 5px;
    font-size: 1.3em;
  }

  .error-details {
    margin-bottom : 10px;
  }
  .text-green{
    color: green;
  }
  .text-red {
    color:red;
  }
  .card .header {
    padding: 5px 0;
    margin-top: -40px;
    max-height: 150px;
    overflow-y: scroll;}
  .light-gray{
    color: lightgray
  }

.analog-sensor-span{
  font-size:2em;
  margin-top:5px;
}
.poor-connection-notification-icon {
  color:#F49D37;
  bottom: 0;
  position: absolute;
  right: 0;
  margin-right: 15px;
  margin-bottom: 15px;
  font-size:1.6em
}
.click-disabled {
  pointer-events: none !important;
}
.no-padding {
  padding:0 !important;
}
.sensorLog-icon {
  position: absolute;
  bottom:0;
  left:0;
  padding: 10px;
}
.text-white{
  color: white
}
.disabled-style{
  color:lightgray;
}


/* .content-w-background{
  position: absolute;
  top: 50px;
  bottom: 0;
} */

.smartRoomTimePicker input {
  background-color: transparent;
  color:white;
  border:0;
  border-bottom: 1px solid white;
  border-radius: 0;
  text-align: center;
}


/* .animatedBOrderDIv {
    border:  1px solid white;
    animation: color-me-in 5s linear infinite;
}
@keyframes color-me-in {
  0% {
    border-color: orange;
  }
  25% {
    border-color: rgb(255, 132, 0);
  }
  50% {
    border-color: rgb(255, 111, 0);
  }
  75%{
    border-color: rgb(255, 81, 0);

  }
  100% {
    border-color:  red;
  }
} */

.table-hover.custom tbody tr:hover {
  color:black !important;
  background-color: #ffffffbf;
}

.form-group label.control-label{
  margin:0
}
.form-group {
  margin-top: 16px
}
.select-group{
  margin-top: 0
}
.composite-form-group>div {
  margin-top: 16px
}

#sliderRootSVG circle {
  fill: none;
  stroke-width: 10px;
  overflow: visible;
}

#sliderRootSVG circle.handle {
  fill: #fff;
  stroke: #CFCFD0;
  stroke-width: 1px;
}

#sliderRootSVG .dashed-circle {
  stroke: #D0D0D0;
}

#sliderRootSVG .top-slider {
  stroke-opacity: 0.8;
}

#sliderRootSVG .handle.same-step-error {
  stroke: #fd3b3f;
}

.device-inside > div:nth-of-type(2){
  height: 30%
} 

.ifThisForm, .thenThatForm {
  height: 100%;
  width: 100%;
  background: rgba(51, 51, 51, 0.631);
  position: fixed;
  top:0;
  left:0;
  z-index: 111;
}

.ifThisForm .text-center.animated.zoomIn, .thenThatForm .text-center.animated.zoomIn {
  position: absolute;
  margin: 15% auto 0 auto;
  right:0;
  left:0;
  background-color:white;
  border-radius: 10px;
  -webkit-box-shadow: 9px 9px 18px -9px rgba(0,0,0,0.75);
-moz-box-shadow: 9px 9px 18px -9px rgba(0,0,0,0.75);
box-shadow: 9px 9px 18px -9px rgba(0,0,0,0.75);
overflow-y: scroll;
} 
.thenThatForm .text-center.animated.zoomIn{
  margin: 20% auto 0 auto !important;
}

.animated.fadeInLeft {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.search-devices input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(202, 221, 218) !important;
  opacity: 1; /* Firefox */
}

.calendar-days.pull-right{
  margin-right: 15px
}
.interruptModePopup {
  position: absolute;
    display: inline-block;
    top: 0;
    right: 0;
    margin: 25px 25px 0 0;
    width: 160px;
    height: auto;
    z-index: 11;
    padding: 0;
    vertical-align: middle;
    text-align: center;
    font-size: 0.8em;
    border-radius: 8px;
    background-color: #afafaf;
    overflow: hidden;
  -webkit-box-shadow: 3px 3px 16px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 3px 3px 16px 0px rgba(0,0,0,0.75);
box-shadow: 3px 3px 16px 0px rgba(0,0,0,0.75);
}
/* 8FBFE0
BFB5AF */
.interruptsDisabled{
  color:#f8a632
}
.interruptsOutEnabled{
color:#8FBFE0
}
/*  ne moze bijela */
.interruptsInEnabled{
  color:#67597A
}

.scene-buttons div, .scene-buttons button{
  /* -webkit-box-shadow: 10px 9px 33px -8px rgba(0,0,0,0.6);
  -moz-box-shadow: 10px 9px 33px -8px rgba(0,0,0,0.6);
  box-shadow: 10px 9px 33px -8px rgba(0,0,0,0.6); */

  -webkit-box-shadow: 1px 2px 6px 2px rgba(0,0,0,0.3); 
box-shadow: 1px 2px 6px 2px rgba(0,0,0,0.3);
}


.register-device-button {
  background-color: #4b7489;
  color: white; 
  border-radius: 5px; 
  display: inline-block;
  padding: 6px 30px;
  -webkit-box-shadow: 2px 3px 17px 2px rgba(0,0,0,0.3); 
  box-shadow: 2px 3px 17px 2px rgba(0,0,0,0.3);
}

 .groupDeleteButton, .groupSaveButton,.groupEditButton {
  /* -webkit-box-shadow: 10px 9px 33px -8px rgba(0,0,0,0.6);
  -moz-box-shadow: 10px 9px 33px -8px rgba(0,0,0,0.6);
  box-shadow: 10px 9px 33px -8px rgba(0,0,0,0.6); */

  -webkit-box-shadow: 1px 2px 6px 2px rgba(0,0,0,0.3); 
  box-shadow: 1px 2px 6px 2px rgba(0,0,0,0.3);
}
.scene-manage-button, .groupSaveButton, .groupDeleteButton, .groupEditButton{
  background-color: #4b7489;
  padding: 6px; 
  color: white; 
  border-radius: 5px; 
  display: inline-block
}
.scene-manage-button:first-child, .scene-manage-button:nth-child(2), .groupSaveButton {
width: 100%;
}
.scene-manage-button:nth-child(3), .scene-manage-button:nth-child(4), .groupEditButton, .groupDeleteButton {
  width: 48%;
  margin-top: 12px
}
.scene-manage-button:nth-child(5) {
  margin-top: 12px;
  width: 100%;
}
.scene-manage-button{
  border: none
}
select{
  background-color: transparent
}

.input-range__label.input-range__label--value{
  font-size: 2em !important;
  top: -2.8rem;
  color:white;
}
.input-range__track--active {
  background: #ffa545;
}
.input-range__slider {
  background: #ffa545;
  border: 1px solid #ffa545;
}
.input-range__track {
  height: 0.4rem;
}
.dialog-center-dive-in{
  width: 90%;
  border-radius: 5px;
  /* border: 1px solid rgb(148, 148, 148); */
  height:92%;
  background-color: rgb(225, 225, 225);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 40px;
  padding:0;
  -webkit-box-shadow: 0px 0px 20px -5px rgba(3,3,3,1);
  box-shadow: 0px 0px 20px -5px rgba(3,3,3,1)
  
}

.dialog-title-dive-in{
  color: white;
  display: table;
  width: 100%;
  height: 30px;
  background: #ffa545;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid rgb(194, 194, 194);
}
.mt-0{
  margin-top: 0 !important;
}
.mb-0{
  margin-bottom: 0 !important;
}
.file-content {
  overflow-y: scroll
}

*:not(input):not(.door-access-management-doors):not(.file-content):not(.whitelist-management-wrapper):not(.show-scrollbar):not(#scrolly):not(.transaction-log-page):not(.scrollable-horizontal-div)::-webkit-scrollbar { 
  display: none; 
}
.file-content::-webkit-scrollbar { 
  display: block !important; 
}

@media screen and (max-width:700px)  {
  .show-scrollbar::-webkit-scrollbar { 
    display: normal !important; 
    width: 10px !important;
  }
}


.show-scrollbar::-webkit-scrollbar { 
  display: normal !important; 
  width: 20px;
}
.show-scrollbar::-webkit-scrollbar-track {
  background: transparent; 
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 5px;
}

/* Handle on hover */
.show-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #888; 
}

.dive-in-frame{
  width:100% !important;
  height: 95% !important;
}

.dive-in-frame.file-content{
  overflow-y: scroll;
  text-align: left
}

.dialog-title-dive-in i {
  padding: 5px;
  font-size: 1.5em
}
.hover-file-folder:hover, .selected-folder-active{
  color: #ffa545
}
.add-new-widget{
  cursor: pointer;
  height: 150px;
  overflow-x: hidden;
  overflow-y: scroll;
  /* width:200px; */
  padding: 20px;
  background-color: transparent
}

.widget-main{
  height: 150px;
  overflow-x: hidden;
  overflow-y: scroll;
  /* width:200px; */
  padding: 20px 0 10px 0;
  background-color: transparent;
  color: "#f0f8ff85"
}
.add-new-widget i {
  color:white
}
.widget-list-items-wrapper{
  max-height: 300px;
  overflow-y: scroll
}

.dashboard-scene, .dashboard-volume, .dashboard-group, .dashboard-analog-output, .dashboard-analog-input, .dashboard-digital-input, .dashboard-digital-output, .dashboard-composite-device{
  height: 100%;
  position: relative;
}

.dashboard-scene div:first-child {
    height: 80%
}
.dashboard-scene div:first-child i{
  font-size: 4em;
  color: #ffffff8c
}
.dashboard-scene div:nth-child(2) {
  height: 20%
}

.dashboard-digital-output .dashboard-item-icon{
width: 100%;
float: left;
}
.dashboard-link-text{
  color: white;
  text-decoration: none;
}
.dashboard-link-text:visited, .dashboard-link-text:hover{
  text-decoration: none !important;
  color: white !important;
}

.dashboard-digital-output .dashboard-item-action-status{
  width: 100%;
  float: right;
}


.dashboard-analog-output .dashboard-item-action-status, .dashboard-composite-device .dashboard-item-action-status, .dashboard-group .dashboard-item-action-status, .dashboard-digital-output .dashboard-item-action-status,
.dashboard-digital-input .dashboard-item-action-status, .dashboard-analog-input .dashboard-item-name, .dashboard-volume .dashboard-item-action-status{
  position: absolute;
  bottom:0;
  text-align: center;
  width: 100%
}

.dashboard-analog-input .dashboard-item-action-status{
  
  position:  absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right:  0;
  margin-top:  30px;
  font-size: 2.6em;

}
.dashboard-analog-input .dashboard-item-icon{
  position:  absolute;
  z-index: 11;
  float:  left;
  bottom: 0;
  margin-left: 15px;

}
.widget-list-items-wrapper .group-selected i, .widget-list-items-wrapper .group-not-selected i{
  margin-top: 20px;
font-size: 1.2em;
margin-right: 5px
}
.widget-list-items-wrapper .group-selected span, .widget-list-items-wrapper .group-not-selected span {
  margin-top: 20px;
  font-size:1.2em;
  margin-left: 5px

}
.dashboard-item-name{
  height: 1.8em;
  overflow-y: scroll;
  width: 100%;
  /* white-space: nowrap;  */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
   
}

.dashboard-analog-input .dashboard-item-name{
  width: 70%;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.dashboard-digital-input .dashboard-item-action-status, .dashboard-group .dashboard-item-action-status{
  font-family: Menlo,Monaco,Consolas,"Courier New",monospace
}
.sort-item-dashboard{
  height: 40px;
  background-color: #ffffff;
  margin: 5px auto;
    width: 200px;
    border-radius: 10px;
    overflow: hidden;
display: inline-block
}
.widget-index-sort {
  color:white;
  font-weight: bold;
  position: relative;
  /* top:50%; */
  -webkit-transform: translateY(-80%);
  -ms-transform: translateY(-80%);
  transform: translateY(-80%);}
  .sort-item-dashboard div {
    line-height: 40px;
    vertical-align: middle
  }
.background-pattern{
  width: 36px;
    height: 14px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==") left center;
}


.location-offline-indicator{
  background: rgba(60,141,189,1);
  background: -moz-linear-gradient(left, rgba(60,141,189,1) 0%, rgba(60,141,189,1) 92%, rgba(239,71,111,1) 93%, rgba(239,71,111,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(60,141,189,1)), color-stop(92%, rgba(60,141,189,1)), color-stop(93%, rgba(239,71,111,1)), color-stop(100%, rgba(239,71,111,1)));
  background: -webkit-linear-gradient(left, rgba(60,141,189,1) 0%, rgba(60,141,189,1) 92%, rgba(239,71,111,1) 93%, rgba(239,71,111,1) 100%);
  background: -o-linear-gradient(left, rgba(60,141,189,1) 0%, rgba(60,141,189,1) 92%, rgba(239,71,111,1) 93%, rgba(239,71,111,1) 100%);
  background: -ms-linear-gradient(left, rgba(60,141,189,1) 0%, rgba(60,141,189,1) 92%, rgba(239,71,111,1) 93%, rgba(239,71,111,1) 100%);
  background: linear-gradient(to right, rgba(60,141,189,1) 0%, rgba(60,141,189,1) 92%, rgba(239,71,111,1) 93%, rgba(239,71,111,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3c8dbd', endColorstr='#ef476f', GradientType=1 );
}

.location-online-indicator{background: rgba(60,141,189,1);
  background: -moz-linear-gradient(left, rgba(60,141,189,1) 0%, rgba(60,141,189,1) 92%, rgba(6,214,160,1) 93%, rgba(6,214,160,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(60,141,189,1)), color-stop(92%, rgba(60,141,189,1)), color-stop(93%, rgba(6,214,160,1)), color-stop(100%, rgba(6,214,160,1)));
  background: -webkit-linear-gradient(left, rgba(60,141,189,1) 0%, rgba(60,141,189,1) 92%, rgba(6,214,160,1) 93%, rgba(6,214,160,1) 100%);
  background: -o-linear-gradient(left, rgba(60,141,189,1) 0%, rgba(60,141,189,1) 92%, rgba(6,214,160,1) 93%, rgba(6,214,160,1) 100%);
  background: -ms-linear-gradient(left, rgba(60,141,189,1) 0%, rgba(60,141,189,1) 92%, rgba(6,214,160,1) 93%, rgba(6,214,160,1) 100%);
  background: linear-gradient(to right, rgba(60,141,189,1) 0%, rgba(60,141,189,1) 92%, rgba(6,214,160,1) 93%, rgba(6,214,160,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3c8dbd', endColorstr='#06d6a0', GradientType=1 );
}

.select-location-common{
  width:96%;
  margin:4px auto;
  position:relative;
  z-index:222;
  padding:8px;
  border-radius:5px;
  overflow:hidden;
}

.exceptions-days-not-selected{
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  /* vertical-align: middle; */
  margin-right: 3px;
  margin-top: 5px;
  background: transparent;
  border: 1px solid rgb(199, 199, 199);
  color: rgb(199, 199, 199);
  text-align: center
}
.exceptions-days-selected{
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  /* vertical-align: middle; */
  margin-right: 3px;
  margin-top: 5px;
  background: rgb(57, 157, 247);
  color: white;
  border: 1px solid rgb(57, 157, 247);
  text-align: center
}

.logs-animation-wrapper .sk-cube{
background-color: #0082c1
}

#scrolly{
  width: 1000px;
  height: 190px;
  overflow: auto;
  overflow-y: hidden;
  margin: 0 auto;
  white-space: nowrap
}

#scrolly img{
  /* width: 300px;
  height: 150px; */
  margin: 20px 10px;
  display: inline;
}

#scrolly::-webkit-scrollbar {
  -webkit-appearance: none;
}
#scrolly::-webkit-scrollbar:horizontal {
  height: 11px;
}
#scrolly::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, .5);
}
#scrolly::-webkit-scrollbar-track { 
  background-color: #fff; 
  border-radius: 8px; 
} 

#scrolly {
  overflow-y: auto;
  height: 100%;
  width:80%;
  line-height: 1em;
  display: inline-block
}

#scrolly::-webkit-scrollbar {
  -webkit-appearance: none;
}

#scrolly::-webkit-scrollbar:vertical {
  display: none
}

#scrolly::-webkit-scrollbar:horizontal {
  height: 11px;
}

#scrolly::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

#video-wrapper canvas {
  padding: 10px;
  border-radius: 10px;
  max-width: 96%
}

#video-wrapper {
  position: relative
}

div[id^="video-canvas"] {
  height:100%;
  width:100%
}
div[id^="video-canvas"] canvas {
  height:100%;
  width:100%
}


.side-streams{
  max-height: 50%;
  overflow-y: scroll
}
.side-streams div{
  height: 120px
}
.side-streams img {
  width:100px;
    float: left;
}

.side-streams h4, .side-streams input {
  display: none
}

.side-streams .error-snapshot-div{
  width:100px;
  height:68px;
  overflow: hidden;
    float: left;
}
/* 
.snapshot-adjusted-view canvas{
  max-width: 100%;  max-height: 100%;

}

.snapshot-adjusted-view{
  width: 100%
}

.snapshot-adjusted-view > div:first-child {
width:80%
}

.snapshot-adjusted-view > div:not(:first-child) {
  width:20%;
  height:100px !important;

  display: inline-block;
  float: right
  }

  .snapshot-adjusted-view > div:not(:first-child) img {
    width:100px;
    float: left;
  
  }

  .snapshot-adjusted-view > div:not(:first-child) input {
    float: right;
  } */

/* #video-wrapper {
  position: relative;
  width: 350px;
  height: 270px;
  margin: 20px auto;
}
#video-wrapper:before {
  display: block;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  top: -10px;
  left: -10px;
  border-top: 1px solid white;
  border-left: 1px solid white;
}
#video-wrapper:after {
  display: block;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  top: -10px;
  right: -10px;
  border-top: 1px solid white;
  border-right: 1px solid white;
}
.video-span:before {
  display: block;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -10px;
  left: -10px;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
}
.video-span:after {
  display: block;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -10px;
  right: -10px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
} */

.m-top-15{
  margin-top: 15px !important
}
.margin-top-5{
  margin-top: 5px 
}
.margin-auto{
  margin:auto
}

.device-event-icon{

  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 1px;
  margin-bottom: 20px;
  color: #3694c0;
  min-width: 15px;
  max-height: 15px;
  width: auto;
  height: auto;
  background-color: #f8a632;
  line-height: 15px;
  vertical-align: middle;
  border:1px solid #f8a632;
  /* border-style: solid;
  border-width: 1px;
  border-color: #f8a632; */
  border-radius: 50%;
  z-index: 1;
}

.bolt-icon{
  height: 18px;
  width: 10px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 10px;
  margin-top: 10px;
}

.search-devices{
  height: 30px;
  margin: 10px;
}
.group-button-icon{
  color: white;
  font-size: 1.4em
}
.triple-font-size{
  font-size: 3em
}
.powermeter-device-data {
  overflow-y:scroll;
  height:50% !important
} 
.inline-element{
  display: inline
}
.margin-t-12{
  margin-top: 12px
}
.shutters-value{
  display:inline-block;
  border:1px solid white;
  border-radius:5px;
  padding:3px 8px
}
.locked-icon-span{
  font-size:2em;
  margin:0
}
.locked-icon-span i{
font-size: 1.5em
}
.m-left-15{
  margin-left: 15px
}
.m-right-5{
  margin-right: 5px
}
.m-left-5{
  margin-left:5px
}
.custom-button-devices{
  margin-left: 5px;
  margin-top: 15px
}
.search-devices-input{
  width: auto;
  display: inline-block;
  color: white;
  min-width: 200px;
}

/*Now the CSS*/

.tree ul {
    padding-top: 20px; position: relative;
	
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
}

.tree li {
	float: left; text-align: center;
	list-style-type: none;
	position: relative;
	padding: 20px 5px 0 5px;
	
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.tree li::before, .tree li::after{
	content: '';
	position: absolute; top: 0; right: 50%;
	border-top: 1px solid #ccc;
	width: 50%; height: 20px;
}
.tree li::after{
	right: auto; left: 50%;
	border-left: 1px solid #ccc;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
.tree li:only-child::after, .tree li:only-child::before {
	display: none;
}

/*Remove space from the top of single children*/
.tree li:only-child{ padding-top: 0;}

/*Remove left connector from first child and 
right connector from last child*/
.tree li:first-child::before, .tree li:last-child::after{
	border: 0 none;
}
/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before{
	border-right: 1px solid #ccc;
	border-radius: 0 5px 0 0;
	-webkit-border-radius: 0 5px 0 0;
	-moz-border-radius: 0 5px 0 0;
}
.tree li:first-child::after{
	border-radius: 5px 0 0 0;
	-webkit-border-radius: 5px 0 0 0;
	-moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree ul ul::before{
	content: '';
	position: absolute; top: 0; left: 50%;
	border-left: 1px solid #ccc;
	width: 0; height: 20px;
}

.tree li span{
	border: 1px solid #ccc !important;
	padding: 5px 10px;
	text-decoration: none;
	color: #666;
	font-family: arial, verdana, tahoma;
	font-size: 11px;
	display: inline-block;
	
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
}

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.tree li span:hover, .tree li span:hover+ul li span {
	background: #c8e4f8; color: #000; border: 1px solid #94a0b4;
}
/*Connector styles on hover*/
.tree li span:hover+ul li::after, 
.tree li span:hover+ul li::before, 
.tree li span:hover+ul::before, 
.tree li span:hover+ul ul::before{
	border-color:  #94a0b4;
}

/*Thats all. I hope you enjoyed it.
Thanks :)*/

.wrap-i2c-register label{
  -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.wrap-i2c-register input {
  display: none;
}
.wrap-i2c-register .btn {
  position: relative;
  display: block;
  float: left;
  overflow: hidden;
  margin-right: 5px;
  width: 48px;
  height: 100px;
  border: 1px solid #fff;
  border-top: none;
  border-radius: 14px;
  background: rgb(211, 211, 211);
  box-shadow: inset 0 -30px 15px -3px #f4eae2;
  cursor: pointer;
  padding: 0
}
.wrap-i2c-register input:checked + .btn:before {
  background-position: 0 200px;
}
.wrap-i2c-register input:checked + .btn .y {
  top: 54px;
}
.wrap-i2c-register .btn:before {
  position: absolute;
  top: 4px;
  left: 4px;
  display: block;
  width: 40px;
  height: 91px;
  border-bottom: 1px solid #fff;
  border-radius: 12px;
  background: rgb(231, 231, 231);
  background: linear-gradient(to bottom,#1db4f0 2%,#1fb7fd 23%,#1fb7fd 48%,#ececec 51%,#ececec 79%,#ececec 100%);
  background-position: 0 100px;
  background-size: 40px 220px;
  box-shadow: inset 0 0 7px 0 rgba(0,0,0,0.05), inset 0 4px 7px -2px rgba(0,0,0,0.5), inset 0 0 10px rgba(255,255,255,0.7), 0 0 5px rgba(255,255,255,0.7);
  content: "";
  transition: background-position .1s ease-in-out;
}
.wrap-i2c-register .btn .y {
  position: absolute;
  top: 4px;
  left: 5px;
  width: 38px;
  height: 38px;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 12px;
  background: #EFE9DF;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.4), inset 0 -5px 7px rgba(0,0,0,0.2), inset 0 -2px 3px rgba(255,255,255,0.3), inset 0 7px 5px rgba(255,255,255,0.8), 0 0px 8px rgba(0,0,0,0.4), 0 0px 3px rgba(0,0,0,0.35);
  transition: top .1s ease-in-out;
}
.wrap-i2c-register .btn .y ul {
  position: absolute;
  top: 11px;
  left: 6px;
  width: 30px;
  height: 20px;
}
.wrap-i2c-register .btn .y li {
  display: block;
  float: left;
  margin: 0 3px 3px 0;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  box-shadow: inset 0 0 4px rgba(0,0,0,0.25), inset -1px 2px 0 rgba(255,255,255,0.7);
}
.deviceManagement-tiles-icon-wrapper{
  height: 100px;
}

.deviceManagement-tiles-icon-wrapper i{
  font-size: 3em;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-top: 30px;
}
.deviceManagement-tiles-wrapper{
  border: 5px solid transparent
}

.deviceManagement-tiles{
  box-sizing: border-box !important;
  position: relative;
  text-align: center;
  /* box-shadow: 1px 3px 17px 2px rgba(0,0,0,0.3); */
  box-shadow: 3px 1px 21px -1px rgba(0,0,0,0.3);
  height: 160px;
  /* margin: 5px 0; */
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding:0;
  /* margin:10px */
}
.deviceManagement-tiles:hover{
  background-color: #3c6275
}
.clear-both{
	clear:both;
}
.overflow-auto{
  overflow:auto
}
.overflow-hidden{
  overflow:hidden
}

.custom-flex-container{
display: flex
}
.custom-flex-element{
margin:auto !important;
}
.full-vh{
  height: 100vh
}
.settings-content{
  overflow-y: scroll;
  position: absolute;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  display: inline-block;
  background-color: #F1F1F1;
  -ms-overflow-style: none;
  overflow-x: hidden;
  background-clip: padding-box;
  border: 5px solid transparent;
  top: 0;
  bottom: 0;
  width: 100%
}
.settings-content>div.card-tab{
  position: relative;
  height: 100%;
  /* overflow-y: scroll; */
}
.settings-content .card-nav-tabs{
  height: 100%;
  padding-top: 45px;
  margin-top: 0;
  overflow-y: hidden
}

.card-nav-tabs .content{
  height: 100% !important;
  padding-bottom: 50px !important
}

@media screen and (max-width:400px){
  .card-nav-tabs .content{
    height: 100% !important;
    padding-bottom: 150px !important
  }
}

.whole-screen-dialog{
  top: 0px; 
    margin-top: 0px;
    left:0px;
    background-color: rgba(0, 0, 0, 0.18)
}
.fixed-position{
  position: fixed
}
.relative-position{
  position: relative;
}
.margin-top-0{
  margin-top: 0
}
.device-modal-dialog-name{
  color:white;
  position:absolute;
  top:0;
  right:0;
  left:0;
  margin-top:5px;
}
.lock-icon-style{
  position: absolute;
  display: inline-block;
  top: 0px;
  left: 0px;
  margin-left: 20px;
  margin-top: 20px;
  z-index: 11;
  color: white;
  font-size: 1.5em;
}
.analog-device-lock-option{
  z-index: 111;
  position: absolute;
  display: inline-block;
  top: 0px;
  left: 0px;
  margin-left: 50px;
  margin-top: 20px;
  color: white;
  padding: 5px 8px;
  margin-right: 5px;
  background-color: #5c717f !important;
  border-radius: 10px;
  box-shadow: none
}
.m-top-50{
  margin-top: 50px
}
.m-left-190{
  margin-left: 190px
}
.m-left-10{
  margin-left: 10px
}
.m-right-10{
  margin-right: 10px
}
.shutters-max-btns{
  padding:12px;
  margin-right: 5px;
  background-color: rgb(92, 113, 127) !important;
  border-radius: 10px;
  box-shadow: none;
  min-width:50px
}
.calibrate-toggle-label{
  color:white;
  display: inline-block;
  margin-top: -15px;
  vertical-align: middle
}

/* -----------------------Confirm dialog----------------- */

 .center-style  {
  height:180px;
  color:black;
}
 .title-style  {
  height:20%;
}
 .dialog-additionalStyle  {
  z-index:111111;
  position:fixed;
  top: 0;
  left: 0;
}
 .dialog-additional-style-for-blinds  {
  z-index:111111;
  position:fixed;
  top: 0;
  right: 0;
  width:100%;
}
 .content-style  {
  height:56%;
  padding:0;
  overflow-y:scroll;
  margin-top:0;
}
 .buttons-style {
  height:20%;
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  margin-bottom:10px;
}
/* ---------------------------------------------------------- */

/* ----------------------Door bell--------------------- */
.display-inline-block{
  display: inline-block
}

.display-inline{
  display: inline !important
}
.display-block{
  display: block
}

.door-bell-wrapper{
  background-color: rgba(130, 130, 130, 0.6);
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100vh;
}
.door-bell-close{
  display:inline-block;
  border:2px solid white;
  border-radius:8px;
  text-align:center;
  color:white;
  padding:5px 10px;
  margin:5px auto;
  cursor:pointer;
}
.door-bell-spinner{
  position: absolute;
  height: 100%;
  color: white;
  margin-left: 0;
  margin-right: 0;
  left: 0;
  right: 0;
  top: 0;
  margin-top: 20%
}
/* ------------------------------------------------------------ */

/* ----------------------------------Footer-------------------- */
.footer-location{
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 15px;
  margin-left: 10px
}
.footer-home{
  font-size:1.5em; 
  padding:15px;
  margin-top:-15px;
}
.fa.fa-times.pointer.side-bar-icon{
  z-index:111 !important;
}
.clr-orange{
  color : #f1a705 !important
}
.notification.dialog-content{
  height:96%
}
.notification-message{
  margin-top:5%
}
.btn-warning.notification-btn{
  width: 90%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin:0 auto 10px auto
}
.poor-connection.dialog-center{
  height:50%;
  margin-top:0;
  top:25%;
  left:0;
  bottom:0;
  right:0
}
.system-messages.dialog-center{
  height:100%;
  margin-top:0;
  width: 100%;
  top:0;
  left:0;
  overflow: auto
  /* bottom:0; */
  /* right:0 */
}
.pwr-mngmnt.analog-output-middle {
  width: 80% !important;
  height: 90% !important;
  overflow: hidden
}
.pwr-mngmnt-modal-data{
  padding-top:30px;
  color:white;
  width:260px;
  margin:0 auto
}
.error-text-wrapper{
  max-height: 200px;
  overflow-y: scroll
}
.err-btn{
  width: 25%

}
.location-scrollable-div{
  overflow-y: scroll;
  max-height: 250px
}
.location-logout-span{
  padding:10px;
  color:blue;
  font-size:1.2em;
}
.clr-ececec{
  color: #ececec;
}
.termo-input-range{
  width:80%;
  margin:0 auto
}

.volume-modal .shutters-max-btns{
  margin-top: 0;
  min-width: 60px;
}

.volume-modal .shutters-max-btns i{
  font-size: 2.3em;
  margin-top: -10px;
  display: block;
}

.volume-modal .shutters-max-btns span{
  margin-top: -10px;
  display: block;
}
.triple-font-size{
  font-size: 3em
}
.edit-widget-icon{
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 1.3em;
  z-index: 1;
  color: white
}

.edit-person-icon{
  position:absolute;
  right:5px; 
  top:5px
}
/* .min-height-200{
  min-height: 200px
} */
.acp-management-modal-button-icons{
  padding: 10px 20px;
  font-size: 1em
}
.full-height-width{
  height: 100%;
  width: 100%;
}
.acp-persons-included{
  background:#CEE5F2;
  border:1px solid grey;
  border-radius:5px;
  margin:3px 0;
  position:relative;
}
.acp-persons-excluded{
  background:transparent;
  border:1px solid grey;
  border-radius:5px;
  margin:3px 0;
  position:relative;
}
.margin-5{
  margin:5px
}
.backup-list-item{
  height: 50px;
  border-bottom: 1px solid grey
}
.backup-list-item>div:first-child{
padding-top: 15px
}
.backup-list-item>div i{
  margin-top: -20px;
  padding: 20px 25px;
}
.add-new-wid{
  color:#f0f8ff85
}
.widget-sort-icon{
  display:inline-block;
  position:absolute;
  top:0;
  left:0;
  padding:5px;
}
.add-new-wid>div:first-child{
margin-top: 5%;
}
.padding-10{
  padding: 10px;

}
.device-buttons-wrapper{
  overflow: auto;
  padding: 5px
}
.padding-5{
  padding: 5px
}

.padding-top-15{
  padding-top: 15px
}

.margin-l-r-0{
  margin-right: 0 !important;
  margin-left:0 !important;
}

.ifttt-pre{
  display: inline;
  border: 0;
  background-color: transparent;
}
.ifttt-delete-item{
  color: grey;
  /* postiton: absolute; */
  top: 0;
  right: 0;
  float: right;
  font-size: 1.1em;
  /* fontWeught: bold; */
  padding: 2px 5px;
  border: 0.5px solid lightGrey;
  border-radius: 5px;
  background-color: #eaeaea;
}

.ifttt-select-device{
  width: 90%
}
.ifttt-day-style{
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  vertical-align: middle;
  margin-right: 3px;
  margin-top: 5px;
}
.ifttt-day-style-access-point{
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  line-height: 22px;
  vertical-align: middle;
  margin-right: 3px;
  margin-top: 5px;
  font-size: 1rem;
}
.ifttt-day-not-selected{
  background: transparent;
  border: 1px solid #c7c7c7;
  color: #c7c7c7;
}
.ifttt-day-selected{
  background: #399df7;
  color: white;
  border: 1px solid #399df7;
}
.ifttt-buttons-ok-cancel{
  /* margin:"5px", */
  padding: 10px 20px;
  font-size: 1em
}
.iftt-event-item {
  display: inline-block;
  overflow: hidden;
}
.ifttt-modal-wrapper{
  border: 0;
  height: 90%;
  overflow-y: scroll;
}
.ifttt-analogInput-input{
  display: inline;
  /* width: 50px; */
  margin-left: 10px
}
.absolute-top-right{
  position: absolute;
  top:0;
  right:0
}

.absolute-bottom-right{
  position: absolute;
  bottom:0;
  right:0
}
.repeatWeekly{
  width: 20px;
  height: 20px;
  line-height: inherit;
  vertical-align: middle;
}
.ifttt-start-time, .ifttt-end-time{
  width: 100;
  margin-top: 16px
}
.ifttt-save-wrapper{
  margin-top: 30px
}
.margin-top-20{
  margin-top: 20px
}
.max-w-200{
  max-width: 200px;
}
.scene-before-devices-wrapper{
  padding-top: 0;
}
.scene-after-devices-wrapper{
  padding-left: 0
}
.console-rubber{
  position:sticky; 
  right:5px;
  top:5px;
}
.color-black{
  color:black !important
}
.abort-loading-btn{
  margin-top: 80px
}

.log-folder{
  padding-left: 10px
}
.log-file{
  padding: 30px 
}
.log-file>div:first-child{
  font-size: 1.2em
}
.sk-cube-grid{
  margin: 150px auto
}
.consumption-info-wrapper{
  padding-top: 20px; 
  padding-bottom: 20px
}
.modbus-text-center{
  text-align: center !important
}

.profile-page .avatar{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.full-percent-height{
  height: 100% !important
}
.profile-link{
  margin-right: 5px;
  margin-top: 10px;
  color: #f8a632;
  font-size: 1.1em;
}
.profile-link:hover{
  color: #f8a632;}

.profile-link i{
  padding: 6px
}
.profile-link button i{
  font-size: 1.5em
}
.relative-center{
  margin-right: auto;
  margin-left: auto
}
.scene-events-link span{
  color:white;
  font-size: 1.5em;
  margin-top: 10px;
  display: inline-block;
  padding:10px 10px 0 10px;
}
.schedule-recurring{
  color: grey;
  margin-top: -5px
}
.widget-sort-scrollable{
  height: 85%;
  overflow-y: scroll
}
.widget-sort-unscrollable{
  height:15%
}
.sort-item-dashboard{
  width: 80%
}

.camera-tile{
  box-sizing: border-box;
  border: 1px solid #ffffff30;
  overflow: hidden;
  padding: 0;
  height: 330px;
  text-align: center;
}
.stream-fetching-wrapper{
  width:320px;
  height:240px;
  margin:0 auto;
}
.stream-fetching-content{
  position: absolute;
  height: 100%;
  color: white;
  margin-left: 0;
  margin-right: 0;
  left: 0;
  right: 0;
  top: 0;
  margin-top: 20%
}

.snapshot-fetching-error{
  background:grey;
  height:240px;
  width:320px;
  margin:0 auto;
}
.snapshot-fetching-img{
  width: 320px;
  height: 240px;
  background: white;
}
.screenshot-wrapper{
  height: 50px;
  width: 60px;
  background-color: white;
  display: inline-block;
  margin: 5px;
  overflow: hidden;
}
.screenshot-img{
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}
.scrollable-horizontal-div{
  background-color: #497080e3;
  height: 70px
}
.delete-screenshots-wrapper{
  width:20%; 
  display:inline-block
}
.delete-screenshots-wrapper i{
  padding-right:10px;
  line-height:70px;
  vertical-align:middle
}
.surveillance-table-wrapper .text-centered{
  text-align: center !important
}
.surveillance-table-wrapper{
  width: 100%;
  overflow-x: auto !important
}
.side-menu-sticker{
  background:#476d83c2;
  z-index:13123234231;
  margin-left:-25px;
  width:20px;
  display:inline-block;
  margin-top:15px;
}

.dashboard-item-action-status input[type=range], .thermostat-middle input[type=range]{
  padding: 12px 0;
  background: transparent;
  cursor: pointer
}
.padding-l-0{
  padding-left: 0
}
.ip-address-wrapper input{
  width: 50px
}

.items-align-via-flex{
  display: flex;
  justify-content: center;
  align-items: center
}

.blurred-bckg{
  float: right;
  width: 100%;
}

.scene-edit-wrapper .disabled-device.device-inside{
  background-color: #a7a7a74f
}
/* margin */
.m1{
  margin: 1rem;
}

.m2{
  margin: 2rem;
}

.mx1{
  margin-right: 1rem;
  margin-left: 1rem;
}

.mx2{
  margin-right: 2rem;
  margin-left: 2rem;
}

.my1{
  margin-top:  1rem;
  margin-bottom: 1rem;
}

.my2{
  margin-top:  2rem;
  margin-bottom: 2rem;
}


/* padding */
.p1{
  padding: 1rem;
}

.p2{
  padding: 2rem;
}

.px1{
  padding-left: 1rem;
  padding-right: 1rem;
}

.px2{
  padding-left: 2rem;
  padding-right: 2rem;
}

.py1{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py2{
  padding-top: 2rem;
  padding-bottom: 2rem;
}


.min-heinght-200{
  min-height: 200px;
}

.addon-tile{
  width: 300px;
  height: 250px;
  overflow-y: scroll;
}
.m-t-20{
  margin-top: 20px
}

.display-flex{
  display: flex
}
.vertical-horizontal-middle-flex{
  display: flex;
  justify-content: center;
  text-align: center
}

.error-messages-counter{
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 5px;
  margin-bottom: 28px;
  color: white;
  min-width: 15px;
  max-height: 15px;
  width: auto;
  height: auto;
  background-color: #ff4545;
  line-height: 15px;
  vertical-align: middle;
  border: 1px solid #ff4545;
  border-radius: 50%;
  z-index: 1;
}
.transaction-page{
  flex: 1;
  height: 80%;
  display: flex;
  flex-direction: row
}
.transaction-iframe-wrapper{
  flex: 1
}
.transaction-payment-info-wrapper{
  flex: 1;
  display: flex;
  /* justify-content: center; */
  /* text-align: center; */
  flex-direction: column
}

.transaction-payment-info-wrapper>div{
  padding: 10px ;
}
.transaction-receipt-placeholder{
  position: relative;
  height: 150px;
  width:60%;
  text-align: center;
  background: white;
  margin: 10px auto
}
.transaction-total{
  position:absolute;
  bottom:0;
  width:100%;
  border-top: 1px solid lightgray
}
.transaction-total strong{
  font-size: 0.875rem
}
.transaction-iframe-wrapper iframe{
  overflow: auto;
  height: 80%;
}
.transaction-page-wrapper{
  display: flex;
  flex-direction: column;
  height: 100%
}
.transaction-status pre{
  color:white;
  font: 1.3rem Inconsolata, monospace;
  background-color: black;
  background-image: radial-gradient(
    rgba(0, 150, 0, 0.75), black 120%
  );
  text-shadow: 0 0 5px #C8C8C8;
}
.transaction-log-row{
  font-size: 0.9em;
  margin: 2px auto;
  background-color: #eaeaea;
  padding: 5px;
  /* overflow: auto;
  overflow-wrap: anywhere;  */
  width: 100%;
  color: black
}
.transaction-row{
  background: #cecece;
  /* color: white; */
  border-radius: 5px;
  margin: 7px 10px;
  padding: 5px;
}

.transaction-log-page{
  overflow-y: auto;
  height: 100%;
}
.transaction-row i{
  font-size: 1.2em;
}
.system-message-log-row{
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 2px auto;
    padding: 5px;
    background-color: #d4d4d4;
}
.system-message-log-row div:first-child{
  flex:1;
  text-align: right
}
.system-message-log-row div:nth-child(2){
  flex:2;
  text-align: center

}
.system-message-log-row div:last-child{
  flex:10;
  text-align: center
}
.bckg-pimatico-orange{
  background-color: #f8a632
}

.btn-pimatico-orange{
  background-color: #f8a632
}
.btn-pimatico-orange:hover{
  background-color: #de942b
}
.color-pimatico-orange{
  color: #f8a632;
}

.blink {
  animation: blink 2s steps(5, start) infinite;
  -webkit-animation: blink 1s steps(5, start) infinite;
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}


.flex-center-center{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.px-2{
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-1{
  padding-left: 1rem;
  padding-right: 1rem;
}
.pointer-important{
  cursor: pointer !important;
}
.flex-1{
  flex: 1;
}
.flex-2{
  flex: 2;
}
.mt-minus1{
  margin-top: -1px !important;
}
.pl-4px{
  padding-left: 4px !important;
}