
.card .content{
	position:static;
	margin-top:0px !important;
	margin-bottom:0px !important;
	background-color: transparent;
	padding: 5px 5px 50px 5px;
	width: 100%;
}

.nav-tabs i.fa{
	font-size: 22px;
	margin-right: 5px;
	vertical-align: middle;
}


.border-15x{
	border-left:15px solid transparent !important;
	border-right:15px solid transparent !important;
}
.tab-pane.active .description{
	display: inline-block;
	width: 80%;
	word-wrap: break-word;
	max-height: 20px;
	/* overflow: scroll; */
}