
#codeEditor{
  width: 100% !important;
  height:100% !important;
 /* height: 84.5vh !important;*/
}

.small-code-editor{
	height: 300px !important;
}
.large-code-editor{
	height: 54vh !important;
}

.border-clear{
	border:0px solid transparent !important;
}

.border-clear.content>div:first-child{
	z-index: 1;
}

.console-log{
	/*width: 100%;*/
	display: block;
	font-size: 13px;
	color:#3c3c3c;
	/*height: 20vh;*/
	/*background-color: #fafafa;*/
	margin-bottom:3px;
}

h4.console-log-header{
	font-weight: bold;
	font-size: 14px;
	font-family: courier;
	margin-left: 5px;
	margin-bottom: 2px;
}

.console-log-content{
	background-color: #fafafa;
	font-family: courier;
	display: block;
	width: 100%;
	/* height: 100%; */
	min-height: 150px;
	padding: 5px 10px 5px 10px;
	border: 0px solid #ccc;
	height: 160px;
  overflow-y: scroll;
}

.clear{
	clear:both;
}

.tab-pane>div>div:last-child {
	margin-bottom:20px;
  }

  .card .content{
	height: 67vh;
    overflow-y: scroll;
	}
	@media screen and (min-width: 1282px){
		.card .content{
			height: 73vh;
				overflow-y: scroll;
			}
	}
