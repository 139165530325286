
.loading-screen-enter{}

.loading-screen-enter.loading-screen-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.loading-screen-leave {
  opacity: 1;
}

.loading-screen-leave.loading-screen-leave-active {
  opacity: 0.01;
  transition: opacity 900ms ease-in;
}



.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}